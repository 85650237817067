import IDrawingUploadFormModel from '@/ship/Models/IDrawingUploadFormModel';

export default class DrawingModel {
    public page!: number;
    public name!: string;
    public typeId!: number | null;
    public apartmentsIds!: number[];
    public projectId!: number | null;
    public sectionsIds!: number[];
    public floorsIds!: number[];

    get getProjectId() {
        return this.projectId;
    }

    set getProjectId(value) {
        this.sectionsIds = [];

        this.projectId = value;
    }

    get getSectionsIds() {
        return this.sectionsIds;
    }

    set getSectionsIds(value) {
        if (!value || value.length !== 1) {
            this.floorsIds = [];
        }

        this.sectionsIds = value;
    }

    get getFloorsIds() {
        return this.floorsIds;
    }

    set getFloorsIds(value) {
        if (!value || value.length !== 1) {
            this.apartmentsIds = [];
        }

        this.floorsIds = value;
    }

    constructor(context?: IDrawingUploadFormModel | null) {
        this.page = context?.page ?? 1;
        this.name = context?.name ?? '';
        this.typeId = context?.typeId ?? null;
        this.projectId = context?.projectId ?? null;
        this.sectionsIds = context?.sectionsIds ?? [];
        this.floorsIds = context?.floorsIds ?? [];
        this.apartmentsIds = context?.apartmentsIds ?? [];
    }

    public getAll(): IDrawingUploadFormModel {
        const { page, name, typeId, projectId, sectionsIds, floorsIds, apartmentsIds } = this;

        return {
            page,
            name,
            typeId,
            projectId,
            sectionsIds,
            floorsIds,
            apartmentsIds,
        };
    }
}
